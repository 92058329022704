import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../components/Layout';
import axios from 'axios';
import { X } from 'lucide-react';
import { trackPageView, trackEvent } from '../analytics';

const SUBJECTS_SPREADSHEET_ID = '1Ar7X_3tDUI0v27VAWwVPT1QusBQaE1EE2i9rZajAvw8';
const COLLECTIONS_SPREADSHEET_ID = '18t_JJyzwhL9FeFk9hnz_7JqZIa_HD3PSYbeYzCKoTuI';
const WORKSHEETS_SPREADSHEET_ID = '1C9IkJ9yew2XBKJVu9dnpXL589l9yYttyfnX6nTc4Sls';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';

const WorksheetCollectionPage = () => {
  const { subjectId, collectionId } = useParams();
  const [subjects, setSubjects] = useState([]);
  const [collections, setCollections] = useState([]);
  const [worksheets, setWorksheets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedWorksheet, setSelectedWorksheet] = useState(null);
  const popupRef = useRef(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const [subjectsResponse, collectionsResponse, worksheetsResponse] = await Promise.all([
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${COLLECTIONS_SPREADSHEET_ID}/values/Sheet1!A2:G?key=${API_KEY}`),
        axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${WORKSHEETS_SPREADSHEET_ID}/values/Sheet1!A2:F?key=${API_KEY}`)
      ]);

      setSubjects(subjectsResponse.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      })));
      setCollections(collectionsResponse.data.values.map(([id, subjectId, title, description, thumbnail, featured, seoText]) => ({
        id, subjectId, title, description, thumbnail, featured: featured === 'TRUE', seoText
      })));
      setWorksheets(worksheetsResponse.data.values.map(([id, collectionId, title, description, pdfUrl, thumbnail]) => ({
        id, collectionId, title, description, pdfUrl, thumbnail
      })));
      setLoading(false);

      trackEvent('worksheet_collection_loaded', {
        subjectId,
        collectionId,
        worksheetsCount: worksheetsResponse.data.values.length
      });
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again later.');
      setLoading(false);

      trackEvent('worksheet_collection_load_error', {
        subjectId,
        collectionId,
        error: err.message
      });
    }
  };

  useEffect(() => {
    fetchData();
    trackPageView(`/worksheets/${subjectId}/${collectionId}`);
  }, [subjectId, collectionId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setSelectedWorksheet(null);
        trackEvent('worksheet_popup_closed', {
          subjectId,
          collectionId
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [subjectId, collectionId]);

  const openPopup = (worksheet) => {
    setSelectedWorksheet(worksheet);
    trackEvent('worksheet_popup_opened', {
      worksheetId: worksheet.id,
      worksheetTitle: worksheet.title,
      collectionId,
      subjectId
    });
  };

  const downloadPDF = async (pdfUrl, title, worksheetId) => {
    try {
      const response = await fetch(pdfUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${title}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      trackEvent('worksheet_pdf_downloaded', {
        worksheetId,
        worksheetTitle: title,
        collectionId,
        subjectId
      });
    } catch (error) {
      console.error('Error downloading PDF:', error);
      alert('Failed to download PDF. Please try again.');
      trackEvent('worksheet_pdf_download_error', {
        worksheetId,
        worksheetTitle: title,
        collectionId,
        subjectId,
        error: error.message
      });
    }
  };

  if (loading) return <Layout><div>Loading...</div></Layout>;
  if (error) return <Layout><div>Error: {error}</div></Layout>;

  const subject = subjects.find(s => s.id === subjectId);
  const collection = collections.find(c => c.id === collectionId);
  const collectionWorksheets = worksheets.filter(w => w.collectionId === collectionId);

  if (!subject || !collection) return <Layout><div>Worksheet collection not found</div></Layout>;

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">{collection.title}</h1>
        <p className="text-xl text-gray-600 mb-8">{collection.description}</p>
        
        {collectionWorksheets.length === 0 ? (
          <p>No worksheets found in this collection.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {collectionWorksheets.map(worksheet => (
              <div key={worksheet.id} className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105">
                <div 
                  className="w-full aspect-[4/3] overflow-hidden cursor-pointer"
                  onClick={() => openPopup(worksheet)}
                >
                  {worksheet.thumbnail && (
                    <img 
                      src={worksheet.thumbnail} 
                      alt={worksheet.title} 
                      className="w-full h-full object-contain" 
                      onLoad={() => trackEvent('worksheet_thumbnail_loaded', {
                        worksheetId: worksheet.id,
                        collectionId,
                        subjectId
                      })}
                      onError={() => trackEvent('worksheet_thumbnail_error', {
                        worksheetId: worksheet.id,
                        collectionId,
                        subjectId
                      })}
                    />
                  )}
                </div>
                <div className="p-4">
                  <h3 className="text-xl font-semibold mb-2">{worksheet.title}</h3>
                  <p className="text-gray-600 mb-4">{worksheet.description}</p>
                  <button 
                    onClick={() => downloadPDF(worksheet.pdfUrl, worksheet.title, worksheet.id)}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors inline-block"
                  >
                    Download PDF
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}

        {/* New SEO text section */}
        {collection.seoText && (
          <div className="mt-12 prose max-w-none">
            <h2 className="text-2xl font-semibold mb-4">More About {collection.title}</h2>
            <div dangerouslySetInnerHTML={{ __html: collection.seoText }} />
          </div>
        )}

        {selectedWorksheet && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
            <div ref={popupRef} className="bg-white rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto relative">
              <button 
                onClick={() => {
                  setSelectedWorksheet(null);
                  trackEvent('worksheet_popup_closed', {
                    worksheetId: selectedWorksheet.id,
                    collectionId,
                    subjectId
                  });
                }}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                aria-label="Close"
              >
                <X size={24} />
              </button>
              <div className="p-4">
                <img 
                  src={selectedWorksheet.thumbnail} 
                  alt={selectedWorksheet.title} 
                  className="w-full object-contain mb-4" 
                  style={{ maxHeight: '60vh' }}
                />
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <h3 className="text-xl font-semibold mb-2 sm:mb-0">{selectedWorksheet.title}</h3>
                  <div className="flex flex-col sm:flex-row gap-2">
                    <button 
                      onClick={() => downloadPDF(selectedWorksheet.pdfUrl, selectedWorksheet.title, selectedWorksheet.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-colors"
                    >
                      Download PDF
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default WorksheetCollectionPage;