import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import axios from 'axios';

const SUBJECTS_SPREADSHEET_ID = '1HQih_vzzxopFNcxVpk1tduefM7O2ZJcJ2-6JBVpClXE';
const API_KEY = 'AIzaSyA0nuOUtyIOA4bvZZZO6E9-zoZnGHLJHmk';

const WorksheetsPage = () => {
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SUBJECTS_SPREADSHEET_ID}/values/Sheet1!A2:D?key=${API_KEY}`);
      setSubjects(response.data.values.map(([id, name, description, thumbnail]) => ({
        id, name, description, thumbnail
      })));
      setLoading(false);
    } catch (err) {
      console.error('Error fetching subjects:', err);
      setError('Failed to load subjects. Please try again later.');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleKeyPress = useCallback((event) => {
    // Secret key combination: Ctrl + Shift + R
    if (event.ctrlKey && event.shiftKey && event.key === 'R') {
      event.preventDefault();
      fetchSubjects();
      console.log('Data refreshed');
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  if (loading) return <Layout><div>Loading subjects...</div></Layout>;
  if (error) return <Layout><div>Error: {error}</div></Layout>;

  return (
    <Layout>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6">All Subjects</h1>
        {subjects.length === 0 ? (
          <p>No subjects found.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {subjects.map(subject => (
              <Link key={subject.id} to={`/worksheets/${subject.id}`} className="block">
                <div className="border p-4 rounded hover:shadow-lg transition-shadow duration-300">
                  {subject.thumbnail && (
                    <img
                      src={subject.thumbnail}
                      alt={subject.name}
                      className="w-full h-40 object-cover mb-4 rounded"
                    />
                  )}
                  <h3 className="text-xl font-semibold mb-2">{subject.name}</h3>
                  <p className="text-gray-600">{subject.description}</p>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default WorksheetsPage;