import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import WorksheetForm from '../components/WorksheetForm';
import FeaturedCollections from '../components/FeaturedCollections';
import { ArrowDownToLine } from 'lucide-react';
import { trackEvent } from '../analytics';

const HomePage = () => {
  const navigate = useNavigate();

  const handleGenerateWorksheet = (formData) => {
    navigate('/worksheet-generator', { state: { formData } });
  };

  const handleAllAboutMeClick = () => {
    trackEvent('back-to-worksheet-clicked', {
      page: 'home'
    });
    navigate('/worksheets/life-skills/back-to-school-worksheet');
  };

  return (
    <Layout>
      <div className="bg-gradient-to-b from-blue-50 to-white">
        <div className="container mx-auto px-4 py-16">
          {/* New All About Me Worksheets button */}
          <div className="flex justify-center mb-8">
            <button
              onClick={handleAllAboutMeClick}
              className="flex items-center px-6 py-3 bg-white text-blue-600 rounded-full shadow-md hover:bg-blue-50 transition-colors duration-300"
            >
              <ArrowDownToLine className="mr-2" size={20} />
              Checkout our Back to school worksheets
            </button>
          </div>

          <div className="max-w-4xl mx-auto">
            <h1 className="text-4xl md:text-5xl font-bold text-center mb-6">
              Free Printable Worksheets
            </h1>
            <p className="text-xl text-center text-gray-600 mb-12">
              Fueling Curiosity, One Worksheet at a Time. Discover free printable worksheets or create your own with the help of AI. Start now!
            </p>
            
            <div className="bg-white rounded-lg shadow-sm p-6 mb-16 max-w-md mx-auto">
              <h2 className="text-2xl font-semibold mb-4 text-center">Generate your worksheet</h2>
              <WorksheetForm onSubmit={handleGenerateWorksheet} />
            </div>
          </div>

          <div className="mb-16">
            <h2 className="text-3xl font-semibold mb-8 text-center">Get free printable worksheets!</h2>
            <FeaturedCollections />
          </div>

          {/* SEO-friendly content section */}
          <div className="max-w-4xl mx-auto mb-16">
            <h2 className="text-3xl font-semibold mb-8 text-center">Explore Our Free Printable Worksheets</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-xl font-semibold mb-3">Comprehensive Subject Coverage</h3>
                <p className="text-gray-600 mb-4">
                  Our free printable worksheets span a wide range of subjects, including math, science, language arts, and social studies. Whether you're looking for addition practice, grammar exercises, or vocabulary builders, we've got you covered.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-3">Grade-Appropriate Content</h3>
                <p className="text-gray-600 mb-4">
                  From preschool to high school, our worksheets are tailored to meet the needs of students at every grade level. Each worksheet is designed to challenge and engage learners while aligning with common educational standards.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-3">Easy to Download and Print</h3>
                <p className="text-gray-600 mb-4">
                  All our worksheets are available in PDF format, making them easy to download, print, and use. Simply choose the worksheet you need, click download, and you're ready to go – no special software required.
                </p>
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-3">Perfect for Teachers and Parents</h3>
                <p className="text-gray-600 mb-4">
                  Whether you're a teacher looking for supplementary materials or a parent supporting your child's learning at home, our free printable worksheets provide valuable resources to enhance education and make learning fun.
                </p>
              </div>
            </div>
          </div>

          <div className="text-center max-w-4xl mx-auto">
            <h2 className="text-3xl font-semibold mb-8">Why choose our worksheet generator?</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-3">Time-saving</h3>
                <p className="text-gray-600">Create custom worksheets in minutes, not hours.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-3">Customizable</h3>
                <p className="text-gray-600">Tailor worksheets to your students' needs and curriculum.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-sm">
                <h3 className="text-xl font-semibold mb-3">Engaging content</h3>
                <p className="text-gray-600">Keep students motivated with varied and interesting exercises.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;